import React, { useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableChartIcon from "@material-ui/icons/TableChart";
import ApprovalIcon from "@material-ui/icons/AssignmentTurnedIn";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/default-avatar.png";
import Badge from "components/Badge/Badge";
import { AddAlert } from "@material-ui/icons";
import { Button, CircularProgress, Icon, Tooltip, Typography, Box } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar";

import "react-loading-skeleton/dist/skeleton.css";
import { useSubscription } from "hooks/useSubscription";
import ManualValidationModal from "components/ManualValidationModal/ManualValidationModal";
import { workerRemap } from "helpers/WorkerRemap";
import { getRealmBySubscription } from "helpers/SubscriptionHelper";
import TabSystem from "components/TabsRadix/TabSystem";
import TabContent from "components/TabsRadix/TabContent";
import { useTranslation } from "react-i18next";
import WorkerRelated from "components/Worker/WorkerRelated";
import { useLocation, useNavigate } from "react-router-dom";
import FieldglassInfo from "components/FieldglassInfo/FieldglassInfo";
import ProfileSummary from "components/ProfileSummary/ProfileSummary";
import ActivityItemsWorker from "components/ActivityItemsTable/ActivityItemsWorker";
import { useWorkerQuery } from "services/input-core/workers/get-worker";
import { useAuth } from "hooks/useAuth";
import WOHRFields from "./Components/WOHRFields/WOHRFields";
import WOSensitiveDataForm from "views/Pages/Workers/Components/WOSensitiveDataForm/WOSensitiveDataForm";
import { useSensitiveData } from "services/input-core/sensitive-data/get-all-sensitive-data";
import { useWorkerActivityItemsWithStatus } from "services/input-core/work-items/get-worker-activity-items-with-status";
import ProcessTable from "components/ProcessTable/ProcessTable";
import AnalysisModal from "components/Analysis/AnalysisModal";
import ButtonTS from "components/CustomButtons/ButtonTS";
import GridItemTS from "components/Grid/GridItemTS";
import { ModalForm } from "components/ModalForm/ModalForm";
import PhysicalCardDialog from "./Components/PhysicalCardDialog/PhysicalCardDialog";
import { useWorkOrderProcessTableQuery } from "services/input-core/worker-orders/get-work-order-process-table";
import { useUploadWorkerAvatar } from "services/input-core/workers/upload-worker-avatar";
import { useBadgeIsValidQuery } from "services/input-core/workers/get-badge-is-blocked";
import CustomDialog from "components/CustomDialog/CustomDialog";
import { useQueryClient } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";
import { useGetFormStatus } from "services/input-core/sensitive-data/get-form-status";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { WorkItemResultEnum, WorkItemStatusEnum, ProfileStatusEnum, RolesEnum } from "utils/@types";
import {
  checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars,
  compareStringsIgnoreCaseAndRemoveSpecialChars,
} from "utils/functions";

const useStyles = makeStyles(styles as any);

const getTabsConfig = (hasRealmRole: any, canProcess: boolean) => {
  const tabs = [
    {
      id: "workers",
      title: "worker-profile-general",
    },
  ];

  if (
    hasRealmRole(RolesEnum.HEALTH_SME) ||
    hasRealmRole(RolesEnum.HUMAN_RESOURCES_ORG_DATA_STRUCTURE) ||
    hasRealmRole(RolesEnum.SUPPLIER_COMPANY_REP) ||
    hasRealmRole(RolesEnum.CONFIDENTIAL_DATA_OBSERVER_MANAGER)
  )
    tabs.push({
      id: "workers2",
      title: "worker-profile-extra-data",
    });

  tabs.push({
    id: "workers3",
    title: "worker-profile-history-log",
  });

  return tabs;
};

const canShowDownloadAction = (processes: any[]): boolean =>
  !(
    processes?.find((process) => process?.type === "PD-0001" || process?.type === "PD-0004") ||
    (processes?.find((process) => process?.type === "PD-0007") &&
      processes?.find((process) => process?.type === "PD-0008"))
  );

export default function Profile() {
  const classes = useStyles();
  const { subscription } = useSubscription();

  const location = useLocation();
  const loadingBarRef = useRef(null);
  const navigate = useNavigate();
  const refId = new URLSearchParams(window.location.search).get("id");
  const { t } = useTranslation("worker-profile");
  const queryClient = useQueryClient();
  const { hasRealmRole } = useAuth();
  const [selectedImage, setSelectedImage] = React.useState(null);
  const { keycloak } = useKeycloak();

  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    color: "primary",
  });

  const [modal, setModal] = React.useState(false);

  const [analyzedContent, setAnalyzedContent] = React.useState([]);
  const [document, setDocument] = React.useState();

  const [currentWorker, setCurrentWorker] = React.useState(
    new URLSearchParams(window.location.search).get("id")
  );

  const [openManualValidationModal, setOpenManualValidationModal] = React.useState(false);
  const [openPhysicalCard, setOpenPhysicalCard] = React.useState(false);

  const [workItemSelected, setWorkItemSelected] = React.useState("");
  const [workOrderID, setWorkOrderID] = React.useState();

  const [tabs, setTabs] = React.useState([
    {
      id: "workers",
      title: "worker-profile-general",
    },
    {
      id: "workers3",
      title: "worker-profile-history-log",
    },
  ]);

  useEffect(() => {
    if (subscription) {
      loadingBarRef?.current?.continuousStart();
      setCurrentWorker(new URLSearchParams(window.location.search).get("id"));
    }
  }, [subscription, location.search]);

  const { data: workOrderSensitiveData, isLoading: workOrderSensitiveDataLoading } =
    useSensitiveData({
      formIdentifier: "WORK_ORDER_PROFILE",
      refId: workOrderID,
    });

  const { data: workOrderProfilePosition, isLoading: workOrderProfilePositionLoading } =
    useSensitiveData({
      formIdentifier: "WORK_ORDER_PROFILE_POSITION",
      refId: workOrderID,
    });

  const {
    data: workerData,
    isLoading: isLoadingWorkerData,
    refetch: refetchWorkerData,
  } = useWorkerQuery(currentWorker);

  const { data: badgeValid, isLoading: isLoadingBadgeValid } = useBadgeIsValidQuery(currentWorker);

  const { data: workerActivityItems, isFetching: isFetchingWorkerActivityItems } =
    useWorkerActivityItemsWithStatus(refId);

  const { data: workerProcess, isFetching: isFetchingWorkerProcess } =
    useWorkOrderProcessTableQuery(workerData?.workOrderID);

  const { mutate: mutateWorkerAvatar, isLoading: isLoadingWorkerAvatar } = useUploadWorkerAvatar();

  const { data: getFormStatus } = useGetFormStatus(
    "WORK_ORDER_PROFILE_POSITION",
    workerData?.workOrderID,
    { enabled: !isLoadingWorkerData }
  );

  useEffect(() => {
    setTabs(getTabsConfig(keycloak.hasRealmRole, getFormStatus?.canProcess));
  }, [getFormStatus?.canProcess]);

  useEffect(() => {
    setWorkOrderID(workerData?.workOrderID);
  }, [workerData?.workOrderID]);

  useEffect(() => {
    if (selectedImage) uploadSelectedImage();
  }, [selectedImage]);

  useEffect(() => {
    if (workerData) {
      if (Object.keys(workerData).length == 0) navigateToWorkerNotFoundPage();

      if (
        workerData?.customFields?.find(
          (cf: any) => cf.name === "SOW Worker Type" && cf.value === "Subcontractor Company"
        )
      ) {
        setTabs([
          {
            id: "workers",
            title: "worker-profile-general",
          },
          {
            id: "workers2",
            title: "worker-profile-extra-data",
          },
          {
            id: "workers3",
            title: "worker-profile-history-log",
          },
        ]);
      }
    }
  }, [workerData]);

  const uploadSelectedImage = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);

    mutateWorkerAvatar(
      { formData: formData, workerId: refId },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["worker", currentWorker]);
          queryClient.invalidateQueries(["worker-physical-card"]);
          refetchWorkerData();
          return;
        },
        onError: () => {
          setNotification({
            open: true,
            color: "danger",
            message: `Image upload failed`,
          });
        },
      }
    );
  };

  const uploadInputRef = useRef(null);

  const handleImageChange = (event: any) => {
    const file = event?.target?.files[0];
    setSelectedImage(file);
  };

  const handleImageClick = () => {
    uploadInputRef.current.click();
  };

  const drawBadgeBasedOnStatus = (workItem: any, t: any) => {
    if (
      checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
        WorkItemResultEnum.PENDING,
        WorkItemResultEnum.IN_PROGRESS,
      ])
    )
      return <Badge color='info'>{workItem?.result}</Badge>;

    if (
      checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
        WorkItemResultEnum.APPROVED,
        WorkItemResultEnum.HOMOLOGADO,
      ])
    )
      return <Badge color='success'>{workItem?.result}</Badge>;

    return (
      <Badge
        color={
          checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
            WorkItemResultEnum.APROVADO_MANUALMENTE,
            WorkItemResultEnum.HOMOLOGADO_MANUALMENTE,
          ])
            ? "success"
            : "danger"
        }
      >
        <Tooltip
          title={
            <div>
              <div>{workItem?.manualValidation?.approved ? t("approved") : t("rejected")}</div>
              <div>{`${t("observation")}: ${workItem?.manualValidation?.observation}`}</div>
              <div>{`${t("expiration-date")}: ${moment(
                workItem?.manualValidation?.expirationDate
              ).format("DD/MM/YYYY")}`}</div>
              <div>{`${t("approved-by")}: ${workItem?.manualValidation?.user}`}</div>
            </div>
          }
          disableHoverListener={
            !checkIfStringInArrayIgnoreCaseAndRemoveSpecialChars(workItem?.result, [
              WorkItemResultEnum.APROVADO_MANUALMENTE,
              WorkItemResultEnum.HOMOLOGADO_MANUALMENTE,
              WorkItemResultEnum.REJEITADO_MANUALMENTE,
            ])
          }
        >
          <span>{t(workItem?.result)}</span>
        </Tooltip>
      </Badge>
    );
  };

  const drawProcessTableRow = (workItem: any) => ({
    code: workItem.type ? workItem.type.toUpperCase() : "Activity Item sin código",
    documentName: workItem?.documents ? (
      workItem?.documents.length
    ) : (
      <Badge color='warning'>No hay documentos analizados</Badge>
    ),
    status: <Badge color='info'>{t(workItem.status)}</Badge>,
    result: drawBadgeBasedOnStatus(workItem, t),
    date: moment(workItem.updatedAt).format("DD/MM/YYYY HH:mm"),
    actions: (
      <div className='actions-right'>
        {workItem.documents
          ? workItem.documents.map((doc: any) => (
              <Tooltip title={doc.name}>
                <ButtonTS
                  justIcon
                  round
                  color='info'
                  href={doc.url}
                  download
                  disabled={!doc.url}
                  target='_blank'
                >
                  <CloudDownloadIcon />
                </ButtonTS>
              </Tooltip>
            ))
          : ""}
        <ButtonTS
          justIcon
          round
          color='success'
          target='_blank'
          onClick={() => handleAnalyzeDocument(workItem?.analyzes, workItem?.documents[0])}
          disabled={!workItem?.analyzes?.length}
        >
          <TableChartIcon />
        </ButtonTS>
        {!workItem.approved &&
          hasRealmRole(["Human Resources - Org Data Structure"]) &&
          workItem.status !== WorkItemStatusEnum.REJEITADO_MANUALMENTE &&
          workItem.status !== WorkItemStatusEnum.PENDING && (
            <ButtonTS
              justIcon
              round
              color='info'
              target='_blank'
              onClick={() => {
                setWorkItemSelected(workItem._id);
                setOpenManualValidationModal(true);
              }}
            >
              <ApprovalIcon />
            </ButtonTS>
          )}
      </div>
    ),
  });

  const handleAnalyzeDocument = (analyzes: any, document: any) => {
    setAnalyzedContent(analyzes);
    setDocument(document);
    setModal(true);
  };

  const onManualValidationFail = () => {
    setNotification({
      open: true,
      color: "danger",
      message: t("manual-validation-fail"),
    });
  };

  const onManualValidationSuccess = () => {
    setNotification({
      open: true,
      color: "success",
      message: t("manual-validation-success"),
    });
  };

  const navigateToWorkerNotFoundPage = () => {
    navigate(`./not-found?id=${refId}`, { replace: true });
  };

  const handleOpenPhysicalCardModal = () => {
    setOpenPhysicalCard(true);
  };

  const renderOpenPhysicalCardModal = () => {
    if (badgeValid?.isValid)
      return (
        <ModalForm
          title={"CONTRACTOR"}
          formName={"Physical Card"}
          open={openPhysicalCard}
          children={<PhysicalCardDialog worker={workerData} setModalOpen={setOpenPhysicalCard} />}
        />
      );
    else
      return (
        <CustomDialog
          handleClose={() => setOpenPhysicalCard(false)}
          open={openPhysicalCard}
          dialogTitle={
            <>
              <Typography className={classes.dialogTitle}>Physical Card</Typography>
            </>
          }
          dialogContent={
            <>
              <Typography style={{ textAlign: "center" }}>{t("badge-invalid")}</Typography>
              <Typography style={{ textAlign: "center" }}>{badgeValid?.data?.error}</Typography>
            </>
          }
          dialogAction={undefined}
        />
      );
  };

  return (
    <div>
      <ManualValidationModal
        open={openManualValidationModal}
        OnSuccess={onManualValidationSuccess}
        OnFail={onManualValidationFail}
        workOrderOrWorkerID={refId}
        setOpen={setOpenManualValidationModal}
        workItemID={workItemSelected}
      />

      {renderOpenPhysicalCardModal()}

      <Snackbar
        place='bc'
        color={notification.color}
        icon={AddAlert}
        message={notification.message}
        open={notification.open}
        closeNotification={() => setNotification({ open: false, message: "", color: "primary" })}
        close
      />

      <AnalysisModal
        analysis={analyzedContent}
        setModal={setModal}
        modal={modal}
        document={document}
      />

      <GridContainer>
        <GridItemTS xs={12} sm={12} md={9} style={{ marginBottom: "0px" }}>
          <TabSystem tabs={tabs}>
            <TabContent value='workers'>
              <GridContainer>
                <GridItemTS xs={12}>
                  <ProfileSummary
                    data={{ ...workerData }}
                    isLoading={isLoadingWorkerData}
                    classes={classes}
                    entityType='worker'
                    statusObj={{}}
                  />
                </GridItemTS>
                <GridItemTS xs={12}>
                  <ActivityItemsWorker
                    canShowDownloadAction={canShowDownloadAction(workerProcess)}
                    workerData={workerData}
                    data={workerActivityItems}
                    isFetching={isFetchingWorkerActivityItems}
                  />
                </GridItemTS>
                <GridItemTS xs={12}>
                  <ProcessTable
                    classes={classes}
                    processData={
                      workerProcess?.map((workerProcess: any) =>
                        drawProcessTableRow(workerProcess)
                      ) ?? []
                    }
                    isProcessLoading={isFetchingWorkerProcess}
                  />
                </GridItemTS>
              </GridContainer>
            </TabContent>
            {workOrderSensitiveDataLoading ? (
              <Box padding={2}>
                <CustomSkeleton numberOfLines={15} height={40} />
              </Box>
            ) : (
              <TabContent value='workers2'>
                <GridContainer>
                  <GridItemTS xs={12} md={6}>
                    <WOSensitiveDataForm
                      type='worker'
                      workOrderId={workOrderID}
                      isAllSensitiveDataLoading={workOrderSensitiveDataLoading}
                      sensitiveData={workOrderSensitiveData}
                      context={{ dateOfBirth: workerData?.dateOfBirth }}
                    />
                  </GridItemTS>
                  <GridItemTS xs={12} md={6}>
                    <WOHRFields
                      canProcess={getFormStatus?.canProcess}
                      type='worker'
                      workOrderId={workOrderID}
                      sensitiveData={workOrderProfilePosition}
                      isAllSensitiveDataLoading={workOrderProfilePositionLoading}
                    />
                  </GridItemTS>
                </GridContainer>
              </TabContent>
            )}
            <TabContent value='workers3'>
              <GridContainer>
                <GridItemTS xs={12}>
                  <WorkerRelated />
                </GridItemTS>
              </GridContainer>
            </TabContent>
          </TabSystem>
        </GridItemTS>
        <GridItemTS xs={12} sm={12} md={3}>
          <Card profile>
            <input
              accept='image/*'
              className={classes.input}
              id='image-upload'
              type='file'
              ref={uploadInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <CardAvatar profile>
              {workerData?.workerIdentification && !selectedImage && (
                <div>
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100px",
                    }}
                    src={workerData?.workerIdentification}
                    alt='Worker Profile Image'
                  />
                </div>
              )}
              {selectedImage ? (
                <img
                  style={{ minWidth: "140px", minHeight: "140px", objectFit: "cover" }}
                  src={URL.createObjectURL(selectedImage)}
                  alt='Worker Profile Image'
                />
              ) : (
                <img
                  style={{ minWidth: "140px", minHeight: "140px" }}
                  src={avatar}
                  alt='Worker Profile Image'
                />
              )}
            </CardAvatar>
            <Button
              onClick={handleImageClick}
              style={{ bottom: "18px", alignSelf: "center", borderRadius: 30 }}
            >
              {isLoadingWorkerAvatar ? (
                <CircularProgress size={20} />
              ) : (
                <Icon style={{ color: "#F44336" }}>create</Icon>
              )}
            </Button>
            <p>{t(`photo-expiration-date`)}:</p>
            <p>{workerData?.workerPhotoEndDate}</p>
            <CardBody profile>
              <h4 className={classes.cardTitle}>
                {workerData?.firstName} {workerData?.lastName}
              </h4>
              {workerData?.status === ProfileStatusEnum.CONFIRMED ||
              workerData?.status === ProfileStatusEnum.ACTIVE ? (
                <Badge color='success'>{t("card-status-active")}</Badge>
              ) : workerData?.status === ProfileStatusEnum.PENDING_APPROVAL ? (
                <Badge color='warning'>{workerData?.status}</Badge>
              ) : (
                <Badge color='danger'>{t("card-status-inactive")}</Badge>
              )}
              <p className={classes.description}>{t("card-description")}</p>
              <ButtonTS
                color='warning'
                round
                href={`${getRealmBySubscription(subscription)}/worker.do?id=${
                  workerData?.workerUpload
                }`}
                target='_blank'
                disabled={!workerData?.workerUpload}
              >
                {t("access-fieldglass")}
              </ButtonTS>
              {hasRealmRole(["Supplier Onboarding Personnel"]) &&
                workerData?.status === ProfileStatusEnum.ACTIVE && (
                  <ButtonTS
                    color='reddit'
                    round
                    onClick={() => handleOpenPhysicalCardModal()}
                    disabled={isLoadingBadgeValid}
                  >
                    {t("open-physical-card-id")}
                  </ButtonTS>
                )}
              <FieldglassInfo
                title='Worker Info'
                fieldGlassData={{ ...workerData }}
                remapper={workerRemap}
              />
            </CardBody>
          </Card>
        </GridItemTS>
      </GridContainer>
    </div>
  );
}
