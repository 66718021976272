import { differenceInYears } from "date-fns";
import { formateDateWithTZ } from "utils/functions";

export function workerRemap(workerObj: {
  customFields: any[];
  locationCode: string;
  locationName: string;
  status: any;
  startDate: any;
  workerEndDate: any;
  siteName: any;
  siteCode: any;
  costCenterName: any;
  businessUnitCode: any;
  businessUnitName: any;
  dateOfBirth: string | number | Date;
  sowWorkerRoleName: any;
  workOrderWorkOrderRevisionOwner: any;
  securityID: any;
  sowWorkerRoleCode: any;
  userID: any;
  workerID: any;
  jobCode: any;
  vendorName: any;
}) {
  const customFields = {};

  workerObj?.customFields?.forEach(
    (customField) => (customFields[customField.name] = customField.value)
  );

  const getLocation = () => {
    const locationsCode = workerObj.locationCode?.split("~");
    const locationsName = workerObj.locationName?.split("~");

    const locationsFormatted = locationsCode?.map(
      (element, index) =>
        element + " - " + (index < locationsName?.length ? locationsName[index] : "")
    );

    return locationsFormatted?.join(", ");
  };

  const workerInfo = {
    Status: workerObj.status,
    "Start Date": workerObj.startDate,
    "End Date": workerObj.workerEndDate,
    Site: `${workerObj.siteName} (${workerObj.siteCode})`,
    Location: getLocation() ?? "-",
    "Cost Center": workerObj.costCenterName,
    BusinessUnit: `${workerObj?.businessUnitCode ?? " "} - ${workerObj?.businessUnitName ?? " "}`,
    "Date of birth": formateDateWithTZ(workerObj.dateOfBirth),
    Age: differenceInYears(new Date(), new Date(workerObj.dateOfBirth)),
    "Job Name": workerObj.sowWorkerRoleName ?? "-",
    Supervisor: workerObj.workOrderWorkOrderRevisionOwner,
    "Profile Worker Primary Contact": workerObj.workOrderWorkOrderRevisionOwner,
    "Security ID": workerObj.securityID,
    "Job Code": workerObj.sowWorkerRoleCode ?? "-",
    "Job Title":
      (workerObj.sowWorkerRoleCode ?? "-") + " / " + (workerObj.sowWorkerRoleName ?? "-"),
    "User Id": workerObj.userID ?? workerObj.workerID ?? "-",
    "Activity Checklist": workerObj.jobCode ? workerObj.jobCode : "",
    "Vendor Name": workerObj.vendorName ? workerObj.vendorName : "",
  };

  return workerInfo;
}
