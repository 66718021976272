import iconECI from '../assets/img/elcorteingles.jpg';
import iconKUO from '../assets/img/kuo_avatar.png';
import iconUverify from '../assets/img/uVerifyMarcaNegativa.png';
import iconAngloUverify from '../assets/img/anglo_american-logo.png';

interface SubscriptionIcon {
    [key: string]: string;
}


export const IconsBySubscription: SubscriptionIcon = {
    "ECI": iconECI,
    "KUO": iconKUO,
    "UVS": iconUverify,
    "AA": iconAngloUverify
}