import { ClassNameMap } from "@material-ui/styles";

export interface MUI_ClassStyles {
  classes?: ClassNameMap<string>;
}

export interface GridItemProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface ButtonProps {
  color:
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "rose"
    | "white"
    | "twitter"
    | "facebook"
    | "google"
    | "linkedin"
    | "pinterest"
    | "youtube"
    | "tumblr"
    | "github"
    | "behance"
    | "dribbble"
    | "reddit"
    | "transparent";
  size?: "sm" | "lg";
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  muiClasses?: any;
  onClick?: () => void;
}

export const RolesEnum = {
  ACCESS_TO_OPERATION_BY_EXCEPTION_APPROVER: "Access To Operation By Exception Approver",
  ADMIN_SUPPORT: "Admin Support",
  COMPANY_REPRESENTATIVE: "Company Representative",
  CONFIDENTIAL_DATA_OBSERVER_MANAGER: "Confidential Data Observer Manager",
  HEALTH_SME: "Health SME",
  HUMAN_RESOURCES_ORG_DATA_STRUCTURE: "Human Resources - Org Data Structure",
  HUMAN_RESOURCES_TIME_ATTENDANCE: "Human Resources - Time & Attendance",
  SAFETY_SME: "Safety SME",
  SUPPLIER_ONBOARDING_PERSONNEL: "Supplier Onboarding Personnel",
  SUPPLIER_COMPANY_REP: "Supplier Company Rep",
  BUYER: "buyer",
  APPROVER: "Approver",
  EHS: "ehs",
  HR: "hr",
  STRATESYS: "stratesys",
  UVERIFY: "uverify",
  UVERIFY_ANGLO: "uverifyAnglo",
  INSURANCE: "insurance",
  LEGAL: "legal",
  SUPER_USER: "superUser",
  SUPPLIER: "supplier",
};

export const StatusBadgeMapping = {
  Pending: { color: "warning", text: "Pending" },
  "Pending Approval": { color: "warning", text: "Pending Approval" },
  "Response Pending Approval": { color: "warning", text: "Response Pending Approval" },
  "Approval Paused": { color: "warning", text: "Approval Paused" },
  "Pending Response": { color: "warning", text: "Pending Response" },
  Blocked: { color: "danger", text: "Blocked" },
  Withdrawn: { color: "danger", text: "Withdrawn" },
  "Response Rejected": { color: "danger", text: "Response Rejected" },
  "No activities": { color: "info", text: "No activities" },
  Rejected: { color: "danger", text: "Rejected" },
  Closed: { color: "danger", text: "Closed" },
  Canceled: { color: "danger", text: "Canceled" },
  Approved: { color: "success", text: "Approved" },
  Completed: { color: "success", text: "Completed" },
  Activated: { color: "success", text: "Activated" },
  Active: { color: "success", text: "Active" },
  Inactive: { color: "danger", text: "Inactive" },
  Confirmed: { color: "success", text: "Confirmed" },
  Granted: { color: "success", text: "Granted" },
  Draft: { color: "info", text: "Draft" },
  Loading: { color: "info", text: "Loading" },
};

export const StatusBadgeEnum = {
  PENDING: "Pending",
  PENDING_APPROVAL: "Pending Approval",
  RESPONSE_PENDING_APPROVAL: "Response Pending Approval",
  APPROVAL_PAUSED: "Approval Paused",
  PENDING_RESPONSE: "Pending Response",
  BLOCKED: "Blocked",
  WITHDRAWN: "Withdrawn",
  RESPONSE_REJECTED: "Response Rejected",
  NO_ACTIVITIES: "No activities",
  REJECTED: "Rejected",
  CLOSED: "Closed",
  CANCELED: "Canceled",
  APPROVED: "Approved",
  COMPLETED: "Completed",
  ACTIVATED: "Activated",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  CONFIRMED: "Confirmed",
  GRANTED: "Granted",
  DRAFT: "Draft",
  LOADING: "Loading",
};

export const StatusColorEnum = {
  COMPLETED: { color: "info", msg: "Puede ser analizado" },
  PENDING: { color: "warning", msg: "Pendiente de Envío" },
  APPROVED: { color: "success", msg: "Aprobado" },
  UNAPPROVED: { color: "danger", msg: "No Aprobado" },
  LOADING: { color: "", msg: "Loading..." },
  CANCELED: { color: "", msg: "Canceled" },
};

export const WorkItemStatusEnum = {
  APROVADO: "APROVADO",
  REJEITADO: "REJEITADO",
  REJEITADO_MANUALMENTE: "REJEITADO MANUALMENTE",
  HOMOLOGADO_MANUALMENTE: "HOMOLOGADO MANUALMENTE",
  FINISH: "FINISH",
  PENDING: "PENDING",
};

export const WorkItemResultEnum = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN PROGRESS",
  APROVADO_MANUALMENTE: "APROVADO MANUALMENTE",
  REJEITADO_MANUALMENTE: "REJEITADO MANUALMENTE",
  HOMOLOGADO: "HOMOLOGADO",
  NAO_HOMOLOGADO: "NÃO HOMOLOGADO",
  HOMOLOGADO_MANUALMENTE: "HOMOLOGADO MANUALMENTE",
  APPROVED: "Approved",
  NOT_APPROVED: "Not Approved",
};

export const ProfileStatusEnum = {
  CONFIRMED: "Confirmed",
  ACTIVE: "Active",
  PENDING_APPROVAL: "Pending Approval",
  PENDING: "Pending",
};

export const ExtractTypeEnum = {
  INVALIDO: "El proceso no se puede completar, documento inválido o extracción",
  CAMPO_NAO_ENCONTRADO: "CAMPO NO ENCONTRADO",
  DOCUMENTO_INVALIDO: "DOCUMENTO INVÁLIDO",
  TIPO_DOCUMENTO: "TIPO DE DOCUMENTO",
};

export const SheFileStatusEnum = {
  PENDING_APPROVE: "PENDING_APPROVE",
  APPROVED: "APPROVED",
  REJECT: "REJECT",
  INVALID_STATUS: "INVALID_STATUS",
};

export const SowStatusEnum = {
  APPROVED: "Approved",
  ACTIVE: "Active",
  APPROVAL_PAUSED: "Approval Paused",
  DRAFT: "Draft",
  PENDING_APPROVAL: "Pending Approval",
  PENDING_RESPONSE: "Pending Response",
  RESPONSE_PENDING_APPROVAL: "Response Pending Approval",
  CLOSED: "Closed",
  RESPONSE_REJECTED: "Response Rejected",
};

export const SensitiveDataStatusEnum = {
  PENDING_DATA: "Pending Data",
  PENDING_HR_FIELDS: "Pending HR Fields",
  PENDING_OREP: "Pending OREP",
  PENDING_APPROVAL: "Pending Approval",
  COMPLETED: "Completed",
};

export const ManualValidationStatusEnum = {
  APPROVED: "approved",
  REJECTED: "rejected",
};
