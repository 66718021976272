/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// @mui material components
import Link from "@mui/material/Link";

import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Button from "components/CustomButtons/ButtonTS";

// Tutorial Images
import selectExtensionsIcon from "./images/selectExtensionsIcon.png";
import installAddOnFromFile from "./images/installAddOnFromFile.png";


import { Close } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useClientConfig } from "services/input-core/blob/get-client-config";

export interface DownloadUverifyExtensionModalProps {
  open: boolean;
  onClose: () => void;
  clientConfig: any;
}

function DownloadUverifyExtensionModal(
  props: DownloadUverifyExtensionModalProps
) {
  const { t } = useTranslation("navbar-links");
  const { onClose, open, clientConfig } = props;
  const [activeStep, setActiveStep] = useState<number>(0);
  const isLastStep: boolean = activeStep === 2;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const downloadExtensionStepsMozilla = [
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant='body1'>
          {t("extension-download-introduction")}.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h6'>{t("extension-step1")}</Typography>
        <Typography variant='body1'>
          - {t("uv-link-label")}:
          <Link
            variant='body1'
            href={clientConfig?.browserExtensionConfig?.firefox ?? ""}
            rel='noopener noreferrer'
            target='_blank'
          >
            {t("uverify-extension")}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h6'> {t("extension-step2")}</Typography>
        <Typography variant='body1'>- {t("extension-step2-text")}.</Typography>
      </Grid>
    </Grid>,

    <Grid container spacing={2}>
      <Grid item>
        <Typography variant='h6'>{t("extension-step3")}</Typography>
        <Typography variant='body1'>
          - {t("option")} 1: {t("extension-step3-text")}.
        </Typography>
        <Typography variant='body1'>
          - {t("option")} 2: {t("click-extension-icon")}.
        </Typography>
      </Grid>
      <Grid item>
        <img
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 2,
          }}
          alt='Click Extensions Icon Demonstrative'
          src={selectExtensionsIcon}
        />
      </Grid>
    </Grid>,

    <Grid container spacing={2}>
      <Grid item>
        <Typography variant='h6'>{t("extension-step4")}</Typography>
        <Typography variant='body1'>- {t("extension-step4-text")}.</Typography>
      </Grid>
      <Grid item>
        <img
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 2,
          }}
          alt='Click Extensions Icon Demonstrative'
          src={installAddOnFromFile}
        />
      </Grid>
      <Grid item>
        <Typography variant='h6'>{t("extension-step5")}</Typography>
        <Typography variant='body1'>- {t("extension-step5-text")}.</Typography>
      </Grid>
      <Grid item>
        <Typography variant='h6'>{t("extension-step6")}</Typography>
        <Typography variant='body1'>- {t("extension-step6-text")}.</Typography>
      </Grid>
    </Grid>,
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      scroll='body'
      PaperProps={{
        style: {
          overflowY: "visible",
        },
      }}
      onClose={() => {
        onClose();
      }}
      disableBackdropClick
    >
      <DialogTitle>
        {t("uverify-extension")}
        <Button
          justIcon
          simple
          color='tumblr'
          round
          size='sm'
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={onClose}
        >
          <Close />
        </Button>
      </DialogTitle>

      <DialogContent style={{ overflowY: "inherit" }}>
        <> {downloadExtensionStepsMozilla[activeStep]}</>
      </DialogContent>

      <DialogActions>
        <Box mt={3} width='100%' display='flex' justifyContent='space-between'>
          {activeStep === 0 ? (
            <Box />
          ) : (
            <Button variant='outlined' color='primary' onClick={handleBack}>
              {t("back")}
            </Button>
          )}
          <Button
            variant='gradient'
            color='primary'
            onClick={isLastStep ? onClose : handleNext}
          >
            {isLastStep ? t("close") : t("next")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadUverifyExtensionModal;
