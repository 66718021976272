import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoutes from "layouts/PrivateRoutes";
import AuthRedirector from "components/AuthRedirector";
import Unauthorized from "views/Pages/Error/Unauthorized";
import AdminRoutes from "views/routes/AdminRoutes";
import LoginPage from "views/Auth/LoginPage";
import { PrefetchingErrorScreen } from "layouts/Prefetcher";
import CheckSSO from "components/CheckSSO";
import { MaintenancePage } from "layouts/MaintenancePage";

export default function App() {
  return (
    <Routes>
      <Route path='/'>
        <Route index element={<Navigate to='/app/dashboard' replace />} />

        <Route path='auth'>
          <Route index element={<Navigate to='/auth/login' replace />} />
          <Route element={<AuthRedirector />}>
            <Route path='login' element={<LoginPage />} />
          </Route>
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path='app/*' element={<AdminRoutes />} />
          <Route path='app/under-maintenance' element={<MaintenancePage />} />
        </Route>
      </Route>

      <Route path='checkSSO' element={<CheckSSO />} />
      <Route path='/blocked' element={<PrefetchingErrorScreen />} />
      <Route path='/unauthorized' element={<Unauthorized />} />
    </Routes>
  );
}
