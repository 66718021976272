import { Box, Button, Typography } from "@material-ui/core";
import { CloudOff } from "@material-ui/icons";
import CardIcon from "components/Card/CardIcon";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";

type dataProps = {
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingFile?: boolean;
  documentsFromActivity?: any;
};

const stepsButtonStyle = {
  backgroundColor: "#2f87ee",
  color: "white",
  width: "140px",
};

export function ActivityItemModalViewer({ isLoadingFile, documentsFromActivity }: dataProps) {
  const fileType = documentsFromActivity?.documents?.filename?.split(".")?.pop();
  const supportedFileTypesForDownload = ["pdf", "zip", "x-gzip", "x-compressed"];

  function downloadFile() {
    const downloadLink = documentsFromActivity?.documents?.url;
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = documentsFromActivity.name;
    link.click();
  }

  if (isLoadingFile) return <CustomSkeleton numberOfLines={1} height={100} />;

  if (!documentsFromActivity?.documents?.url) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CardIcon>
          <CloudOff />
        </CardIcon>
        <Typography> File not found!</Typography>
      </div>
    );
  }

  if (supportedFileTypesForDownload.includes(fileType)) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>
          {" "}
          {documentsFromActivity?.documents?.filename.substring(0, 40) +
            "(...)." +
            documentsFromActivity?.documents?.filename.split(".")[1]}
        </p>
        <Button variant='contained' onClick={downloadFile} style={stepsButtonStyle} type='submit'>
          Download file
        </Button>
      </Box>
    );
  }

  return (
    <img
      src={documentsFromActivity?.documents?.url}
      alt='File'
      style={{ maxWidth: "90vw", maxHeight: "90vh" }}
    />
  );
}
